<template>
  <div class="content">
    <div class="col-6 mx-auto">
      <form
        class="form-group d-flex flex-column align-content-center flex-wrap"
        @submit.prevent="onSubmit"
      >
        <div v-for="(entry, index) in state.item" :key="`row_${index}`">
          <div class="row" v-if="entry.inputType == 'input'">
            <label class="col-form-label col-2 flex-wrap">{{
              entry.label
            }}</label>
            <input
              class="form-control col-10"
              type="text"
              v-model="entry.value"
              :placeholder="entry.label"
              :disabled="state.mode == 'ReadAdminItem'"
            />
          </div>
          <div
            class="row"
            v-if="entry.inputType == 'selectBox'"
            style="height: 32px"
          >
            <label class="col-form-label col-2 flex-wrap">{{
              entry.label
            }}</label>
          </div>
        </div>

        <!-- 관리자정보 조회페이지 -->
        <div
          class="btn-group-vertical col-12"
          v-if="state.mode == 'ReadAdminItem'"
        >
          <button class="btn btn-primary col-6 mx-auto" @click="clickModifyBtn">
            수정
          </button>
          <button class="btn btn-primary col-6 mx-auto" @click="clickRemoveBtn">
            삭제
          </button>
        </div>
        <!-- 관리자정보 등록페이지- -->
        <div
          class="btn-group-vertical col-12"
          v-if="state.mode == 'CreateAdminItem'"
        >
          <button class="btn btn-primary col-6 mx-auto" @click="clickPostBtn">
            저장
          </button>
        </div>
        <!-- 관리자정보 수정페이지- -->
        <div
          class="btn-group-vertical col-12"
          v-if="state.mode == 'UpdateAdminItem'"
        >
          <button class="btn btn-primary col-6 mx-auto" @click="clickSaveBtn">
            저장
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { onMounted, onUpdated, reactive, watch } from "vue";
import memberService from "@/services/member.service";

// # hooks
const router = useRouter();
const routerParams = useRoute().params;

// # state
const state = reactive({
  mode: router.currentRoute.value.name || "",
  item: {
    email: {
      label: "이메일",
      value: "",
      inputType: "input",
    },
    password: {
      label: "비밀번호",
      value: "",
      inputType: "input",
    },
  },
  id: routerParams.id || "",
});

// # method
// 단건 조회
const getItem = async () => {
  const data = (await memberService.findOne(state.id)).data;
  for (var key in state.item) {
    state.item[key].value = data[key];
  }
};

// # Btn Click Event
const clickPostBtn = async () => {
  const params = {};
  for (var key in state.item) {
    params[key] = state.item[key].value;
  }
  await memberService.create(params);
  router.push({
    name: "ReadAdminList",
  });
};

const clickModifyBtn = () => {
  router.push({
    name: "UpdateAdminItem",
    params: { id: state.id },
  });
  state.mode = "UpdateAdminItem";
};

const clickSaveBtn = async () => {
  const params = {};
  for (var key in state.item) {
    params[key] = state.item[key].value;
  }
  await memberService.update(state.id, params);
  router.push({
    name: "ReadAdminItem",
    params: { id: state.id },
  });
  state.mode = "ReadAdminItem";
};

const clickRemoveBtn = async () => {
  await memberService.delete(state.id);
  router.push({
    name: "ReadAdminList",
  });
};

// # life cycle
onMounted(() => {
  if (state.mode != "CreateAdminItem") {
    getItem();
  }
});
</script>

<style lang="scss" scoped>
.btn-group {
  display: flex;
  gap: 10px;
  * {
    padding: 5px 10px;
    background-color: #ddda;
    border-radius: 5px;
    color: #666;
    &:hover {
      background-color: #ddd;
    }
  }
}
</style>
